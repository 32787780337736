import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/offplan_register.json"
import DefaultForm from "../forms/default-form-layout"
import "./OffplanContact.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanContact = ({ offplanData, whatsappData,isPreviewEnabled }) => {
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const findImage=offplanData?.images?.strapi_json_value?.length>0?offplanData?.images?.strapi_json_value[0]:""

  const propImage =
    findImage
      ? findImage?.url
      :offplanData?.images?.url?offplanData?.images?.url: ""

  const whatsappNum = offplanData?.whatsapp
    ? offplanData?.whatsapp
    : "971502304695"

  const {isLargeScreen} =useDeviceMedia()

  const qrData=offplanData?.qr_code?.strapi_json_value?.length>0?offplanData?.qr_code?.strapi_json_value:"";
  const qrImage=offplanData?.qr_code?.validationQr?offplanData?.qr_code?.validationQr
                :qrData[0]?.validationQr
  const permitNumber=offplanData?.qr_code?.permitNumber?offplanData?.qr_code?.permitNumber
                     :qrData[0]?.permitNumber

  const text = `Hi, I saw this property on haus & haus ${pageurl} and would like to know more.`


  return (
    <div className={`offplan-contact-details`} id="offplan-contact-form">
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="contact-sections">
            <div className="content-sections">
              <h4>{offplanData?.form_title}</h4>
              {(offplanData?.form_content?.data?.form_content ||(isPreviewEnabled&&offplanData?.form_content))&&
              <p className="contact-intro">
                <ContentModule
                  Content={offplanData?.form_content?.data?.form_content||offplanData?.form_content}
                />
              </p>
              }
              <div className="social-details">
                <div>
                  <a
                    href={`https://wa.me/${whatsappNum?.replace(/[+\s]/g, '')}?text=${encodeURIComponent(text)}`}
                    target="_blank"
                  >
                    <icon className="icon green-whatsapp-icon" />
                    <span className="whatsapp">Click to WhatsApp</span>
                  </a>
                </div>
                <div className="horizontal-line"></div>
                <div>
                  <a className="email-id" href={`mailto:info@hausandhaus.com`}>
                    <icon className="icon green-envelop-icon" />
                    <span>info@hausandhaus.com</span>
                  </a>
                </div>
              </div>
              {!isLargeScreen&&(qrData||qrImage)&&
              <div className="qr-code-module-desktop">
                <div className="image-section">
                  <img src={qrImage} alt="qr code" />
                </div>
                <div className="qr-content">
                  <div className="icon-text-holder">
                    <h5>DLD Permit Number:</h5>
                 
                        <div 
                          className="info-icon-module">
                          <i className="icon offplan-info-icon"/>
                          <span className="tri-icon"><i className="icon triangle-icon" /></span>
                          <div className={`show-when-hover`}>
                            <p>
                              This QR code indicates you're dealing with a reliable listing verified by the Dubai Land Department. You can also scan the code to access the property details.
                            </p>
                          </div>
                        </div>
                  </div>
                  <p>{permitNumber}</p>
                  <p className="qr-text">
                    This property listing has been reviewed and verified by Dubai Land Department
                  </p>
                </div>
              </div>
              }
            </div>
            <div className="form-section__footer offplan_footer-form">
              <DefaultForm
                fields={FormFields}
                sourceUrl={pageurl}
                prop_address={offplanData?.banner_title}
                prop_img_url={propImage}
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com, raizul@hausandhaus.com, zainul.abedeen@hausandhaus.com, stephen.n@hausandhaus.com"
                    : "hauswebsiteleads@gmail.com, raizul@hausandhaus.com, zainul.abedeen@hausandhaus.com, stephen.n@hausandhaus.com"
                }
              />
            </div>
            {isLargeScreen&&(qrData||qrImage)&&
             <div className="qr-code-module-offplan">
              <div className="image-section">
                <img src={qrImage} alt="qr code" />
              </div>
             <div className="qr-content">
                  <div className="icon-text-holder">
                    <h5>DLD Permit Number:</h5>
                    <p className="num">{permitNumber}</p>
                        <div 
                          className="info-icon-module">
                          <i className="icon offplan-info-icon"/>
                          <span className="tri-icon"><i className="icon triangle-icon" /></span>
                          <div className={`show-when-hover`}>
                            <p>
                            The Dubai Land Department strongly advises all customers and 
                            investors to only engage with real estate advertisements that 
                            feature the QR Code
                            </p>
                          </div>
                        </div>
                  </div>
                  <p className="qr-text">
                    This property listing has been reviewed and verified by Dubai Land Department
                  </p>
              </div>
           </div>
            }
          </div>
        </Container>
      </ScrollAnimation>
    </div>
  )
}

export default OffplanContact
